import { v4 as uuidV4 } from 'uuid';

import { IBrokerAccount, IBrokerGateway, IBrokerProvider } from '../../types';

import { defaultFormValues } from './constants';
import {
  AccountFormValue,
  AdditionalParamFormItem,
  FormValues,
  GatewayFormValue,
} from './types';

const getParamType = (
  value: string | number | boolean,
): AdditionalParamFormItem['type'] => {
  switch (typeof value) {
    case 'boolean':
      return 'boolean';

    case 'number':
      return 'number';

    default:
      return 'string';
  }
};

export const getAdditionalParamsFormRows = (
  additionalParams?: Record<string, string | number | boolean>,
): Array<AdditionalParamFormItem> => {
  if (!additionalParams) {
    return [];
  }

  return Object.keys(additionalParams).map((key) => ({
    uuid: uuidV4(),
    key,
    value: additionalParams[key],
    type: getParamType(additionalParams[key]),
  }));
};

export const getAdditionalParamsPayload = (
  formValues: Array<AdditionalParamFormItem>,
): Record<string, string | number | boolean> => {
  if (!formValues || formValues.length === 0) {
    return {};
  }

  return formValues.reduce<Record<string, string | number | boolean>>(
    (acc, item) => {
      if (item.type === 'boolean') {
        return {
          ...acc,
          [item.key]: Boolean(item.value),
        };
      }

      if (item.type === 'number') {
        return {
          ...acc,
          [item.key]: parseInt(item.value as string, 10),
        };
      }

      return {
        ...acc,
        [item.key]: item.value.toString(),
      };
    },
    {} as Record<string, string | number | boolean>,
  );
};

export const mapAccountsToForm = (
  data?: Record<string, IBrokerAccount>,
): Array<AccountFormValue> => {
  if (!data) {
    return [];
  }

  return Object.keys(data).map<AccountFormValue>((uuid) => {
    const {
      name = '',
      settlementCounterparty = '',
      executionCounterparty = '',
      legalEntity = '',
      settlementCounterpartyAccount = '',
      account = '',
      accountDescription = '',
      clientId = '',
      additionalParams = {},
    } = data[uuid];

    return {
      uuid,
      name,
      settlementCounterparty,
      executionCounterparty,
      legalEntity,
      settlementCounterpartyAccount,
      account,
      accountDescription,
      clientId,
      additionalParams: getAdditionalParamsFormRows(additionalParams),
    };
  });
};

export const mapGatewaysToForm = (
  data?: Record<string, IBrokerGateway>,
): Array<GatewayFormValue> => {
  if (!data) {
    return [];
  }

  return Object.keys(data).map<GatewayFormValue>((uuid) => {
    const { accounts, address, manualExecution, name, environment, capacity } =
      data[uuid];

    return {
      uuid,
      name,
      address,

      manualExecution: manualExecution || false,
      environment: environment || '',
      capacity: capacity?.toString() || '',
      accounts: mapAccountsToForm(accounts),
    };
  });
};

export const getFormValue = (payload: IBrokerProvider | null): FormValues => {
  if (!payload) {
    return defaultFormValues;
  }

  return {
    name: payload.name || defaultFormValues.name,
    providerType: payload.providerType || defaultFormValues.providerType,
    executionCounterparty:
      payload.executionCounterparty || defaultFormValues.executionCounterparty,
    gateways: mapGatewaysToForm(payload.gateways) || defaultFormValues.gateways,
  };
};

export const mapAccountsToPayload = (
  data: Array<AccountFormValue>,
): Record<string, IBrokerAccount> | null => {
  if (data.length === 0) {
    return null;
  }

  return data.reduce<Record<string, IBrokerAccount>>((result, item) => {
    const {
      uuid,
      name,
      settlementCounterparty,
      executionCounterparty,
      legalEntity,
      settlementCounterpartyAccount,
      account,
      accountDescription,
      clientId,
      additionalParams,
    } = item;

    return {
      ...result,

      [uuid]: {
        name,
        settlementCounterparty,

        ...{ additionalParams: getAdditionalParamsPayload(additionalParams) },
        ...(executionCounterparty && { executionCounterparty }),
        ...(legalEntity && { legalEntity }),
        ...(settlementCounterpartyAccount && { settlementCounterpartyAccount }),
        ...(account && { account }),
        ...(accountDescription && { accountDescription }),
        ...(clientId && { clientId }),
      },
    };
  }, {});
};

export const mapGatewaysToPayload = (
  data: Array<GatewayFormValue>,
): Record<string, IBrokerGateway> | null => {
  if (data?.length === 0) {
    return null;
  }

  return data.reduce<Record<string, IBrokerGateway>>((result, item) => {
    const accounts = mapAccountsToPayload(item.accounts);
    const capacity = parseInt(item.capacity, 10);

    const { environment, uuid, address, name, manualExecution } = item;

    return {
      ...result,

      [uuid]: {
        address,
        name,
        manualExecution,

        ...(accounts && { accounts }),
        ...(environment && { environment }),
        ...(!Number.isNaN(capacity) && { capacity }),
      },
    };
  }, {});
};

export const getPayloadValue = (
  value: FormValues,
): Partial<IBrokerProvider> => {
  const gateways = mapGatewaysToPayload(value.gateways);

  return {
    name: value.name,
    ...(value.providerType && { providerType: value.providerType }),
    ...(value.executionCounterparty && {
      executionCounterparty: value.executionCounterparty,
    }),
    ...(gateways && { gateways }),
  };
};
