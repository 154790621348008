import {
  Checkbox,
  IconButton,
  Input,
  InputDatePicker,
} from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import {
  ArrayContainer,
  ButtonsContainer,
  DeleteButton,
  SpacedContainer,
  Title,
  VerticalContainer,
} from '../styled';

import { Item } from './styled';
import useSplits from './useSplits';

const Splits = () => {
  const {
    disabled,
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    registerCheckboxField,
    registerDateField,
    registerDigitsField,
    value,
  } = useSplits();

  return (
    <ArrayContainer disabled={disabled}>
      <SpacedContainer>
        <Title>Splits</Title>

        <ButtonsContainer>
          {isInherited && (
            <InheritButton
              value={inheritTitle}
              isActive={isResetButtonActive}
              onReset={handleReset}
            />
          )}

          <IconButton
            onClick={handleAddItem}
            iconName="AddIcon"
            iconColor="promo"
            iconSize={22}
          />
        </ButtonsContainer>
      </SpacedContainer>
      <VerticalContainer>
        {value?.map((item, index) => (
          <Item key={item.uuid}>
            <InputDatePicker {...registerDateField(`${index}.timestamp`)} />

            <Input
              fullWidth
              label="From"
              {...registerDigitsField(`${index}.coefficient.from`)}
            />

            <Input
              fullWidth
              label="To"
              {...registerDigitsField(`${index}.coefficient.to`)}
            />

            <Checkbox
              label="Ignore Auto Scale"
              {...registerCheckboxField(`${index}.ignoreAutoScale`)}
            />

            <DeleteButton
              iconName="DeleteIcon"
              iconColor="radical"
              onClick={() => handleRemoveItem(index)}
            />
          </Item>
        ))}
      </VerticalContainer>
    </ArrayContainer>
  );
};

export default Splits;
