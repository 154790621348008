import { IconButton } from 'react-ui-kit-exante';
import styled from 'styled-components';

export const ArrayContainer = styled.div<{ disabled: boolean }>`
  display: grid;
  grid-template-rows: 48px auto;
  gap: 12px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  inherited?: boolean;
}>`
  display: grid;
  grid-template-rows: ${({ inherited }) => (inherited ? '32px 1fr' : '1fr')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  flex-shrink: 1;
`;

export const SpacedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const HorizontalContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  gap: 12px;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DeleteButton = styled(IconButton)`
  display: flex;
  height: 100%;
  align-self: center;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
