import dayjs from 'dayjs';
import { IconButton, Input, InputDatePicker } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import {
  CircleClockContainer,
  Container,
  DatePickerWrapper,
  DateTimeWrapper,
} from './styled';
import { DateProps } from './types';
import useDateTime from './useDateTime';

const DateTime = ({ children, path }: DateProps) => {
  const {
    timeError,
    dateError,
    dateValue,
    timeValue,
    disabled,
    handleDateChange,
    handleTimeChange,
    handleDateClear,
    handleDateReset,
    handleTimeClear,
    handleTimeReset,
    isDateInherited,
    isTimeInherited,
    inheritDateTitle,
    inheritTimeTitle,
    isResetDateButtonActive,
    isResetTimeButtonActive,
  } = useDateTime(path);

  return (
    <Container>
      <InputContainer disabled={disabled} inherited>
        {isDateInherited ? (
          <InheritButton
            value={inheritDateTitle}
            isActive={isResetDateButtonActive}
            onReset={handleDateReset}
          />
        ) : (
          <div />
        )}

        <DatePickerWrapper>
          <InputDatePicker
            dateFormat="dd/MM/yyyy"
            disabled={disabled}
            inputProps={{
              error: Boolean(dateError),
              fullWidth: true,
              label: children?.toString(),
              message: dateError,
            }}
            onChange={handleDateChange}
            value={
              dateValue ? dayjs(dateValue).format('DD/MM/YYYY') : undefined
            }
          />
          <IconButton
            disabled={disabled}
            iconColor="secondary"
            iconName="CloseIcon"
            iconSize={28}
            onClick={handleDateClear}
          />
        </DatePickerWrapper>
      </InputContainer>
      <InputContainer disabled={disabled} inherited>
        {isTimeInherited ? (
          <InheritButton
            value={inheritTimeTitle}
            isActive={isResetTimeButtonActive}
            onReset={handleTimeReset}
          />
        ) : (
          <div />
        )}

        <DatePickerWrapper>
          <Input
            placeholder="hh:mm:ss"
            value={timeValue}
            error={Boolean(timeError)}
            message={timeError}
            iconRight={
              <DateTimeWrapper>
                <CircleClockContainer applyStates size={24} />
              </DateTimeWrapper>
            }
            onChange={(event) => handleTimeChange(event.target.value)}
          />
          <IconButton
            disabled={disabled}
            iconColor="secondary"
            iconName="CloseIcon"
            iconSize={28}
            onClick={handleTimeClear}
          />
        </DatePickerWrapper>
      </InputContainer>
    </Container>
  );
};

export default DateTime;
