import { IconButton } from 'react-ui-kit-exante';

import { Modal } from '~/components/Modal';
import { PromptDialog } from '~/components/PromptDialog';

import { DeleteDialogProps } from './types';

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isShown,
  isLoading,
  onConfirm,
  onDecline,
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleDecline = () => {
    if (onDecline) {
      onDecline();
    }
  };

  if (!isShown) {
    return null;
  }

  return (
    <Modal onPressEscape={onDecline} open={isShown}>
      <PromptDialog
        confirmButton={
          <IconButton
            iconColor={isLoading ? 'action' : 'secondary'}
            iconName="DeleteIcon"
            iconSize={24}
            label="Delete"
            onClick={handleConfirm}
            type="submit"
          />
        }
        declineButton={
          <IconButton
            iconColor="secondary"
            iconName="CloseIcon"
            iconSize={24}
            onClick={handleDecline}
          />
        }
      >
        Are you sure want delete?
      </PromptDialog>
    </Modal>
  );
};

export default DeleteDialog;
