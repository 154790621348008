import * as yup from 'yup';

function getValidationSchema(currencyIDs: Set<string>) {
  return yup.object().shape({
    _id: yup
      .string()
      .required('Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
      .test('name-is-unique', 'ID must be unique', function uniqueIDs(value) {
        return !currencyIDs.has(value);
      }),
    icon: yup.string().url('Icon should be a valid URL'),
    description: yup.string(),
    leverageRate: yup.number().required(),
    leverageRateShort: yup.number().required(),
    extremeLeverageRate: yup.number().nullable(),
    extremeLeverageRateShort: yup.number().nullable(),
    fractionDigits: yup.number().nullable(),
    currencySymbol: yup.string().nullable(),
    weight: yup.number().nullable(),
    code: yup.number().nullable(),
    isNav: yup.boolean(),
    isCrypto: yup.boolean(),
  });
}

export default getValidationSchema;
