import { ReactJsonViewProps } from 'react-json-view';

export const DEFAULT_JSON_VIEWER_PARAMS: Partial<ReactJsonViewProps> = {
  collapseStringsAfterLength: false,
  collapsed: false,
  displayDataTypes: false,
  displayObjectSize: false,
  enableClipboard: true,
  iconStyle: 'triangle',
  indentWidth: 4,
  name: null,
  style: {
    fontSize: '13px',
    width: '100%',
  },
  theme: 'rjv-default',
};
