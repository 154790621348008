import { CircleClockIcon, styled } from 'react-ui-kit-exante';

export const Container = styled('div')(() => ({
  display: 'grid',
  gap: '12px',
  gridTemplateColumns: '1fr 180px',
}));

export const DatePickerWrapper = styled('div')(() => ({
  display: 'grid',
  gridGap: '4px',
  gridTemplateColumns: '1fr 24px',

  '& > div > div': {
    width: '100%',
  },
}));

export const DateTimeWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.color.typo.secondary,
  display: 'flex',
  gap: '4px',
}));

export const CircleClockContainer = styled(CircleClockIcon)(({ theme }) => ({
  color: theme.color.icon.promo,
}));
