import { JsonViewer } from 'react-ui-kit-exante';

import { Container, Column, Header } from './styled';
import { SourceViewProps } from './types';
import useSmartTabs from './useSourceView';

const defaultProps = {
  style: {
    fontSize: '14px',
    height: 'calc(100vh - 190px)',
  },
};

const SourceView = ({ dependencies }: SourceViewProps) => {
  const [value, diff] = useSmartTabs(dependencies);

  return (
    <Container>
      <Column>
        <Header>Instrument Value</Header>
        <JsonViewer src={value} {...defaultProps} />
      </Column>

      <Column>
        <Header>Difference</Header>
        <JsonViewer src={diff} {...defaultProps} />
      </Column>
    </Container>
  );
};

export default SourceView;
