import * as yup from 'yup';

const NUMBERS_ONLY = 'Field must be a number';

const EmptyNumber = (msg: string) =>
  yup.lazy((value) => {
    if (value === '') {
      return yup.string();
    }

    return yup.number().typeError(msg);
  });

const gatewaySchema = yup.object({
  name: yup.string().required(),
  source: yup.string().required(),
  address: yup.string().required(),
  environment: yup.string().required(),
  delayed: yup.boolean(),
  description: yup.string(),
  capacity: EmptyNumber(NUMBERS_ONLY),
});

const formSchema = yup
  .object({
    name: yup.string().required(),
    providerType: yup.string(),
    gateways: yup.array().of(gatewaySchema).required(),
  })
  .required();

export default formSchema;
