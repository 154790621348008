import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep, isEqual } from 'lodash';
import {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { IconButton, Input, Notification, Switch } from 'react-ui-kit-exante';

import { JsonViewerWrapper } from '~/components/JsonViewerWrapper';

import { symbolDBService } from '../../../services/symbolDB.service';
import { getProp } from '../../../utils/getProp';
import { SuccessMessages } from '../../Themes/constants';
import { AddField } from '../components/AddLocale/AddField';
import {
  AddFieldContainer,
  Control,
  Controls,
  FormContainer,
  FormPanelContainer,
  SwitchContainer,
} from '../styled';
import {
  ILocalization,
  ILocalizationFormProps,
  ILocalizationFormValues,
} from '../types';

import { schema } from './LocalizationForm.schema';

export const LocalizationForm: FC<ILocalizationFormProps> = ({
  locale,
  locales,
  addLocale,
  addField,
  deleteField,
  onDelete,
  isNewLocalization,
  onUpdateLocalizations,
  onClose,
  title = 'New localization',
}) => {
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    unregister,
    formState: { errors, isDirty },
  } = useForm<ILocalizationFormValues>({
    defaultValues: locale,
    resolver: yupResolver(schema),
  });
  const initialValue = useRef<ILocalization>(cloneDeep(locale));
  const [isDiff, setIsDiff] = useState(false);
  const [isJSON, setIsJSON] = useState(false);
  const [valuesJSON, setValuesJSON] = useState<Partial<ILocalization>>(locale);

  const handleDelete = useCallback(() => {
    const { key } = locale;
    onDelete(key as string);
  }, [onDelete, locale]);

  const onSubmit = useCallback(
    async (values: ILocalizationFormValues) => {
      try {
        const { key, ...rest } = values;

        if (isNewLocalization) {
          await symbolDBService().createLocalization(values);
        } else {
          await symbolDBService().updateLocalization(rest, key);
        }
        Notification.success({
          title: isNewLocalization
            ? SuccessMessages.Create
            : SuccessMessages.Update,
        });
        onUpdateLocalizations();
      } catch (error: any) {
        Notification.error(error?.message);
      } finally {
        onClose();
      }
    },
    [isNewLocalization, onUpdateLocalizations, onClose],
  );

  const handleOnBlur = () => {
    const values: ILocalization = getValues();

    setValuesJSON(values);
  };

  useEffect(() => {
    const isLocalizationChanged = initialValue.current.key === locale.key;
    reset(
      { ...locale },
      { keepValues: isLocalizationChanged, keepDirty: isLocalizationChanged },
    );
  }, [locale, reset, initialValue]);

  useEffect(() => {
    if (initialValue.current.key !== locale.key) {
      initialValue.current = locale;
      setIsDiff(false);

      return;
    }

    setIsDiff(() => !isEqual(initialValue.current, locale));
  }, [locale]);

  const controls = useMemo(
    () => (
      <Controls>
        <IconButton
          data-test-id="localizations__button--save"
          disabled={!isDirty && !isDiff}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={20}
          label="Save"
          type="submit"
        />
        {!isNewLocalization && (
          <IconButton
            data-test-id="localizations__button--delete"
            iconColor="radical"
            iconName="DeleteIcon"
            iconSize={20}
            label="Delete"
            onClick={handleDelete}
            type="button"
          />
        )}
        <IconButton
          data-test-id="localizations__button--close"
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={20}
          onClick={onClose}
        />
      </Controls>
    ),
    [isDiff, isDirty, isNewLocalization, handleDelete, onClose],
  );

  const renderControl = useCallback(
    (key: keyof ILocalizationFormValues): ReactNode => {
      const prop = getProp(locale, key);
      const error = getProp(errors, key) as FieldError;

      const isObject =
        typeof prop === 'object' && !Array.isArray(prop) && prop !== null;

      if (isObject) {
        const keys = Object.keys(prop);
        return keys.map((k, index) => {
          const nestedKey = `${key}.${k}`;
          const isLastField = index === keys.length - 1;
          const [, localeName] = key.split('.');

          return (
            <Fragment key={nestedKey}>
              {renderControl(nestedKey as keyof ILocalizationFormValues)}
              {isLastField && key !== 'localization' && (
                <AddFieldContainer
                  dataTestIdPrefix="add-field"
                  placeholder="Field name"
                  buttonText="Add field"
                  onClick={(value) => addField(value, localeName)}
                />
              )}
            </Fragment>
          );
        });
      }

      const [, localeName, fieldName] = key.split('.');
      const isDisabled = !isNewLocalization && key === 'key';

      const handleDeleteField = () => {
        if (localeName === 'default' && fieldName === 'default') {
          Notification.error({
            title:
              'You cannot remove this field, instead remove the localization completely',
          });
          return;
        }
        unregister(`localization.${localeName}.${fieldName}`, {
          keepDirty: false,
        });
        deleteField(localeName, fieldName);
      };

      return (
        <Control key={key}>
          <Controller
            key={key}
            name={key}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                data-test-id={`localizations__input--${key}`}
                disabled={isDisabled}
                error={Boolean(error)}
                fullWidth
                label={key}
                {...field}
              />
            )}
          />
          {key !== 'key' && (
            <IconButton
              data-test-id="localizations__button--delete-field"
              iconColor="secondary"
              iconName="CloseIcon"
              iconSize={20}
              onClick={handleDeleteField}
            />
          )}
        </Control>
      );
    },
    [
      addField,
      control,
      deleteField,
      errors,
      isNewLocalization,
      locale,
      unregister,
    ],
  );

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)} onBlur={handleOnBlur}>
      <FormPanelContainer action={controls} title={locale?.key || title}>
        {!isNewLocalization && (
          <SwitchContainer isJSON={isJSON}>
            <Switch
              checked={isJSON}
              label="JSON"
              onChange={() => setIsJSON(!isJSON)}
            />
          </SwitchContainer>
        )}

        {isJSON && locale && (
          <JsonViewerWrapper
            data={valuesJSON}
            fontSize="14px"
            height="calc(100vh - 280px)"
          />
        )}

        {!isJSON && (
          <>
            {(Object.keys(locale) as Array<keyof typeof locale>).map(
              renderControl,
            )}
            <AddField
              dataTestIdPrefix="add-locale"
              onClick={addLocale}
              placeholder="Locale name"
              buttonText="Add locale"
              options={locales}
            />
          </>
        )}
      </FormPanelContainer>
    </FormContainer>
  );
};
