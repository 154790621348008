import {
  ExecutionSchemaItem,
  FormValue,
  FormValueTrade,
  TradeItem,
} from '~/pages/ExecutionSchemes/types';
import { mapUnsetBoolToFormValue, mapUnsetFormValueToBool } from '~/utils/form';

import { defaultFormValues } from './constants';

export const mapTradeItemToFormValueTrade = (
  data: TradeItem,
  defaultLegalEntity: string,
  uuid: number,
): FormValueTrade => {
  const {
    legalEntity = defaultLegalEntity,
    logicType,
    name,

    accountId = '',
    allowedEntities = [],
    counterpartyAccountId = '',
    counterpartyClientId = '',
    executionCounterparty = '',
    forbiddenEntities = [],
    settlementCounterparty = '',
    side = '',
    userId = '',
  } = data;

  return {
    uuid: uuid.toString(),

    legalEntity,
    logicType,
    name,

    accountId,
    allowedEntities,
    counterpartyAccountId,
    counterpartyClientId,
    executionCounterparty,
    forbiddenEntities,
    settlementCounterparty,
    side,
    userId,
    applyMarkup: mapUnsetBoolToFormValue('applyMarkup', data),
    overrideSymbolId: mapUnsetBoolToFormValue('overrideSymbolId', data),
  };
};

export const mapPayloadToFormValue = (
  payload: ExecutionSchemaItem | null,
  defaultLegalEntity: string,
): FormValue => {
  if (!payload) {
    return defaultFormValues;
  }

  const { name = '', description = '', trades = [] } = payload;

  return {
    name,
    description,
    trades: trades.map((item, uuid) =>
      mapTradeItemToFormValueTrade(item, defaultLegalEntity, uuid),
    ),
  };
};

export const mapFormTradesToPayloadTradeItem = (
  formTrades: FormValueTrade[],
): TradeItem[] | undefined => {
  if (formTrades.length === 0) {
    return undefined;
  }

  return formTrades.map((item) => ({
    legalEntity: item.legalEntity,
    logicType: item.logicType,
    name: item.name,

    ...mapUnsetFormValueToBool<TradeItem>('applyMarkup', item.applyMarkup),
    ...mapUnsetFormValueToBool<TradeItem>(
      'overrideSymbolId',
      item.overrideSymbolId,
    ),

    ...(item.accountId && { accountId: item.accountId }),
    ...(item.counterpartyAccountId && {
      counterpartyAccountId: item.counterpartyAccountId,
    }),
    ...(item.counterpartyClientId && {
      counterpartyClientId: item.counterpartyClientId,
    }),
    ...(item.executionCounterparty && {
      executionCounterparty: item.executionCounterparty,
    }),
    ...(item.settlementCounterparty && {
      settlementCounterparty: item.settlementCounterparty,
    }),
    ...(item.side && { side: item.side }),
    ...(item.userId && { userId: item.userId }),
    ...(item.allowedEntities?.length > 0 && {
      allowedEntities: item.allowedEntities,
    }),
    ...(item.forbiddenEntities?.length > 0 && {
      forbiddenEntities: item.forbiddenEntities,
    }),
  }));
};

export const mapFormValueToPayload = (
  value: FormValue,
): Partial<ExecutionSchemaItem> => {
  const { name, description } = value;

  const trades = mapFormTradesToPayloadTradeItem(value.trades);

  return {
    name,
    ...(description && { description }),
    ...(trades && { trades }),
  };
};
