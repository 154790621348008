import { InstrumentActions } from './actions';
import {
  onAddingChildStarted,
  onAffectedSymbolsError,
  onAffectedSymbolsStart,
  onAffectedSymbolsSucceed,
  onAppendOverride,
  onDeleteError,
  onDeleteStart,
  onDeleteSucceed,
  onDiscardChanges,
  onFetchError,
  onFetchStart,
  onFetchSucceed,
  onHideAffectedSymbolsDialog,
  onRemove,
  onSaveError,
  onSaveStart,
  onSaveSucceed,
  onSetFieldValue,
  onSetReady,
  onStackOrderChange,
} from './handlers';
import { InstrumentReducer, InstrumentState } from './types';
import validate from './validation';

const reducer: InstrumentReducer = (state, action): InstrumentState => {
  switch (action.type) {
    case InstrumentActions.AppendOverride:
      return validate(onAppendOverride(state, action.payload));

    case InstrumentActions.AddingChildStarted:
      return onAddingChildStarted(state, action.payload);

    case InstrumentActions.DeleteError:
      return onDeleteError(state);

    case InstrumentActions.DeleteStart:
      return onDeleteStart(state);

    case InstrumentActions.DeleteSucceed:
      return onDeleteSucceed();

    case InstrumentActions.DiscardChanges:
      return validate(onDiscardChanges(state, action.payload));

    case InstrumentActions.FetchError:
      return onFetchError(state, action.payload);

    case InstrumentActions.FetchStart:
      return onFetchStart();

    case InstrumentActions.FetchSucceed:
      return onFetchSucceed(state, action.payload);

    case InstrumentActions.Remove:
      return validate(onRemove(state, action.payload));

    case InstrumentActions.SaveError:
      return onSaveError(state);

    case InstrumentActions.SaveStart:
      return validate(onSaveStart(state));

    case InstrumentActions.SaveSucceed:
      return onSaveSucceed(state);

    case InstrumentActions.SetFieldValue:
      return validate(
        onSetFieldValue(state, action.payload.path, action.payload.value),
      );

    case InstrumentActions.SetReady: {
      return onSetReady();
    }

    case InstrumentActions.StackOrderChange:
      return validate(
        onStackOrderChange(state, action.payload.path, action.payload.nodes),
      );

    case InstrumentActions.AffectedSymbolsError:
      return onAffectedSymbolsError(state);

    case InstrumentActions.AffectedSymbolsStart:
      return onAffectedSymbolsStart(state);

    case InstrumentActions.AffectedSymbolsSucceed:
      return onAffectedSymbolsSucceed(state, action.payload);

    case InstrumentActions.HideAffectedSymbolsDialog:
      return onHideAffectedSymbolsDialog(state);

    default:
      return state;
  }
};

export default reducer;
