import { useContext, useEffect, useMemo, useState } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';
import { DateObject } from '~/types/shared';

import { getDateObject, getDateValue, getDateString } from '../utils';

const useDate = (path: string) => {
  const { state, dispatch } = useContext(InstrumentContext);
  const selfValue = getSelfValue<DateObject>(path, state.values);
  const inheritValue = getInheritValue<DateObject>(path, state.parents);
  const initialValue = getDateValue(selfValue || inheritValue);

  const [value, setValue] = useState<Date | null>(initialValue);

  const isInherited = inheritValue !== undefined;
  const disabled = state.saveStatus.pending;
  const isResetButtonActive = Boolean(inheritValue && selfValue !== undefined);

  const inheritTitle = useMemo(
    () => getDateString(inheritValue),
    [inheritValue],
  );

  const handleChange = (date: Date | null) => {
    if (!disabled) {
      setValue(date);
      const payloadValue = getDateObject(date);

      if (payloadValue) {
        dispatch({
          type: InstrumentActions.SetFieldValue,
          payload: { path, value: payloadValue },
        });
      }
    }
  };

  const handleReset = () => {
    if (isInherited && !disabled) {
      setValue(getDateValue(inheritValue));

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  const error = state.errors.get(path);

  useEffect(() => {
    setValue(initialValue);
  }, [path, JSON.stringify(initialValue)]);

  return {
    isInherited,
    isResetButtonActive,
    inheritValue,
    inheritTitle,
    error,
    value,
    disabled,
    handleChange,
    handleReset,
  };
};

export default useDate;
