import { useMemo } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { ISelectOption } from 'react-ui-kit-exante';

import { defaultRuleSchedule } from '~/pages/Schedules/components/ScheduleForm/constants';
import { RuleSchedule, ScheduleForm } from '~/pages/Schedules/types';

const getName = (
  ruleSchedule: RuleSchedule,
  options: Array<ISelectOption>,
): [string, string] => {
  const fromMarker = options.find(
    (opt) => opt.value === ruleSchedule.from.marker,
  );

  const toMarker = options.find((opt) => opt.value === ruleSchedule.to.marker);
  const full = `${ruleSchedule.from.time} (${fromMarker?.label}) - ${ruleSchedule.to.time} (${toMarker?.label})`;
  const short = `${ruleSchedule.from.time} - ${ruleSchedule.to.time}`;

  return [short, full];
};

const useScheduleSection = (
  form: UseFormReturn<ScheduleForm>,
  ruleIndex: number,
  id: string | null,
  marketOptions: Array<ISelectOption>,
) => {
  const arr = form.watch(`rules.${ruleIndex}.schedule`);

  const list = useMemo(() => {
    if (!arr) {
      return [];
    }

    return arr.map((item) => {
      const [name] = getName(item, marketOptions);

      return {
        id: Math.random().toString(),
        name,
      };
    });
  }, [arr]);

  const fieldControl = useFieldArray({
    name: `rules.${ruleIndex}.schedule`,
    control: form.control,
  });

  const scheduleRuleIndex = useMemo<number>(
    () => list.findIndex((item) => item.id === id),
    [list, id],
  );

  const getLabel = (matchId: string) => {
    const index = list.findIndex((item) => item.id === matchId);

    const [name] = getName(
      form.watch(`rules.${ruleIndex}.schedule.${index}`),
      marketOptions,
    );

    return name;
  };

  const getTooltip = (matchId: string) => {
    const index = list.findIndex((item) => item.id === matchId);

    const [, tooltip] = getName(
      form.watch(`rules.${ruleIndex}.schedule.${index}`),
      marketOptions,
    );

    return tooltip;
  };

  const handleAppend = () => {
    fieldControl.prepend([defaultRuleSchedule]);
  };

  const handleRemove = (index: number) => {
    fieldControl.remove(index);
  };

  return {
    handleAppend,
    list,
    fieldControl,
    getLabel,
    getTooltip,
    scheduleRuleIndex,
    handleRemove,
  };
};

export default useScheduleSection;
