import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';

const useSelect = (path: string, options: ISelectOption[]) => {
  const { state, dispatch } = useContext(InstrumentContext);
  const selfValue = getSelfValue<string>(path, state.values);
  const inheritValue = getInheritValue<string>(path, state.parents);

  const [value, setValue] = useState(selfValue || inheritValue);

  const disabled = state.saveStatus.pending;
  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = Boolean(inheritValue && selfValue !== undefined);

  const inheritTitle = useMemo(() => {
    const option = options.find((item) => item.value === inheritValue);

    return (option?.label as string) || inheritValue;
  }, [inheritValue, options]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      setValue(target.value);
      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: target.value },
      });
    }
  };

  const handleReset = () => {
    if (isInherited && !disabled) {
      setValue(inheritValue);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  const error = state.errors.get(path);

  useEffect(() => {
    setValue(selfValue || inheritValue);
  }, [path, selfValue, inheritValue]);

  return {
    isInherited,
    isResetButtonActive,
    inheritValue,
    inheritTitle,
    error,
    value,
    disabled,
    handleChange,
    handleReset,
  };
};

export default useSelect;
