import { diff } from 'json-diff';
import { useContext, useMemo } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { getInstrumentPayload } from '~/pages/Instruments/context/utils';

import { SourceViewProps } from './types';

export const useSourceView = (
  dependencies: SourceViewProps['dependencies'],
) => {
  const { state } = useContext(InstrumentContext);

  const payload = useMemo(
    () => getInstrumentPayload({ ...state.values }, dependencies),
    [state.values, dependencies],
  );

  const viewDiff = useMemo(
    () => diff(getInstrumentPayload({ ...state.ref }, dependencies), payload),
    [payload],
  );

  return [payload, viewDiff];
};

export default useSourceView;
