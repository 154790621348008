import { Checkbox, styled } from 'react-ui-kit-exante';

export const Container = styled('div')(() => ({
  alignSelf: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
}));

export const TableContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  border: `1px solid ${theme.color.input.border}`,
  borderRadius: '4px',
  cursor: 'default',
  display: 'grid',
  gridGap: '1px',
  gridTemplateColumns: '190px repeat(7, 60px)',
  gridTemplateRows: 'repeat(7, 36px)',
  overflow: 'hidden',
}));

export const Header = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  display: 'flex',

  '& span': {
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
    fontSize: '12px',
    height: '22px',
    justifyContent: 'center',
    width: '61px',
  },

  '& span:first-child': {
    width: '190px',
  },
}));

export const Cell = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.primary,
  borderBottom: `1px solid ${theme.color.input.border}`,
  borderLeft: `1px solid ${theme.color.input.border}`,
  borderRight: `1px solid ${theme.color.input.border}`,
  display: 'flex',
  justifyContent: 'center',
}));

export const Row = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.primary,
  borderBottom: `1px solid ${theme.color.input.border}`,
  color: theme.color.typo.primary,
  display: 'flex',
  fontSize: '14px',
  padding: '0 10px',
}));

export const CheckboxContainer = styled(Checkbox)(() => ({
  transform: 'translateX(3px)',
}));
