import { FC, useMemo } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { DurationType, DurationTypeValue } from '~/types/models';

import {
  Cell,
  CheckboxContainer,
  Container,
  Header,
  Row,
  TableContainer,
} from './styled';
import { DurationTypesProps } from './types';
import { Schema } from './utils';

const initialValue = {
  MARKET: [],
  LIMIT: [],
  STOP: [],
  STOP_LIMIT: [],
  TWAP: [],
  ICEBERG: [],
  TRAILING_STOP: [],
};

const DurationTypes: FC<DurationTypesProps> = ({ value, title, onChange }) => {
  const parsedValue = useMemo<DurationTypeValue>(
    () => ({ ...initialValue, ...value }),
    [value],
  );

  const handleChange = (key: keyof DurationTypeValue, type: DurationType) => {
    const existIndex = parsedValue[key].findIndex((t) => t === type);

    const payload =
      existIndex === -1
        ? { [key]: [...parsedValue[key], type] }
        : {
            [key]: parsedValue[key].filter((t) => t !== type),
          };

    onChange({ ...parsedValue, ...payload });
  };

  return (
    <Container>
      <Header>
        <span>{title}</span>
        <Tooltip placement="top" title="Day">
          <span>DAY</span>
        </Tooltip>
        <Tooltip placement="top" title="Good till cancel">
          <span>GTC</span>
        </Tooltip>
        <Tooltip placement="top" title="Good till time">
          <span>GTT</span>
        </Tooltip>
        <Tooltip placement="top" title="Immediate or cancel">
          <span>IOC</span>
        </Tooltip>
        <Tooltip placement="top" title="Fill or kill">
          <span>FOK</span>
        </Tooltip>
        <Tooltip placement="top" title="At the opening">
          <span>ATO</span>
        </Tooltip>
        <Tooltip placement="top" title="At the close">
          <span>ATC</span>
        </Tooltip>
      </Header>
      <TableContainer>
        {Schema.map((row) => (
          <>
            <Row>{row.title}</Row>
            {row.cells.map((cell) => (
              <Cell key={cell.id}>
                <CheckboxContainer
                  id={cell.id}
                  checked={parsedValue[cell.key].includes(cell.type)}
                  onChange={() => handleChange(cell.key, cell.type)}
                />
              </Cell>
            ))}
          </>
        ))}
      </TableContainer>
    </Container>
  );
};

export default DurationTypes;
