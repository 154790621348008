import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import useFetchStatus from '~/hooks/useFetchStatus';
import {
  ExecutionSchemaItem,
  ExecutionSchemaParams,
  FormValue,
} from '~/pages/ExecutionSchemes/types';
import { symbolDBService } from '~/services/symbolDB.service';
import getErrorDescription from '~/utils/getErrorDescription';

import { mapFormValueToPayload, mapPayloadToFormValue } from '../utils';
import validationSchema from '../validation';

type UseScheduleProps = {
  onSuccess: (payload: ExecutionSchemaItem) => void;
  onDelete?: (id: string) => void;
  onError?: () => void;
};

const useExecutionSchemaForm = (
  { onSuccess, onDelete, onError }: UseScheduleProps,
  defaultLegalEntity: string,
) => {
  const { id } = useParams<ExecutionSchemaParams>();

  const [executionSchema, setExecutionSchema] =
    useState<ExecutionSchemaItem | null>(null);

  const [isJSON, setIsJSON] = useState(false);

  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();
  const [savingStatus, savingStatusActions] = useFetchStatus();
  const [deletingStatus, deletingStatusActions] = useFetchStatus();

  const form = useForm<FormValue>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const fetchPayload = async (schemaId?: string) => {
    if (schemaId) {
      fetchingStatusActions.handleStart();
      try {
        const data = await symbolDBService().getExecutionSchema(schemaId);
        setExecutionSchema(data);
        form.reset(mapPayloadToFormValue(data, defaultLegalEntity));
        fetchingStatusActions.handleSuccess();
      } catch (e) {
        fetchingStatusActions.handleError(e as AxiosError);
      }
    } else {
      setExecutionSchema(null);
      form.reset(mapPayloadToFormValue(null, defaultLegalEntity));
    }
  };

  const handleDelete = async () => {
    if (id && onDelete) {
      try {
        deletingStatusActions.handleStart();
        await symbolDBService().deleteExecutionSchema(id);
        onDelete(id);
        deletingStatusActions.handleSuccess();
      } catch (e) {
        deletingStatusActions.handleError(e as AxiosError);

        if (onError) {
          onError();
        }
      }
    }
  };

  const handleSave = async (value: FormValue) => {
    const result = mapFormValueToPayload(value);

    savingStatusActions.handleStart();

    try {
      const data = id
        ? await symbolDBService().updateExecutionSchema(id, result)
        : await symbolDBService().createExecutionSchema(result);

      form.reset(
        mapPayloadToFormValue({ ...result, ...data }, defaultLegalEntity),
      );

      onSuccess({ ...executionSchema, ...data });
      savingStatusActions.handleSuccess();
      Notification.success({ title: 'Execution Schema saved' });
    } catch (e: unknown) {
      if (onError) {
        onError();
      }

      const isExistError =
        (e as AxiosError).response?.data?.description?.name ===
        'already exists';

      if (isExistError) {
        Notification.error({
          title: 'Execution Schema with this name already exist',
        });
      }

      Notification.error({
        title: getErrorDescription(
          (e as AxiosError).response?.data?.description,
        ).join(' : '),
      });

      savingStatusActions.handleError(e as AxiosError, { quiet: true });
    }
  };

  const title = useMemo(() => {
    if (!id) {
      return 'New Execution Schema';
    }

    const schemaTitle = executionSchema?.name || 'Unnamed Schema';

    return fetchingStatus.isSucceed ? schemaTitle : '';
  }, [id, fetchingStatus.isSucceed]);

  const {
    formState: { dirtyFields },
    handleSubmit,
  } = form;

  const values = form.getValues();
  const jsonViewData = useMemo(() => {
    if (!id || !fetchingStatus.isSucceed) {
      return {};
    }

    return mapFormValueToPayload(values);
  }, [values, id, fetchingStatus.isSucceed]);

  const isDirty = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    fetchPayload(id);
  }, [id]);

  return {
    deletingStatus,
    executionSchema,
    fetchingStatus,
    form,
    handleDelete,
    id,
    isDirty,
    isJSON,
    isNew: !id,
    isSaveDisabled: !isDirty || savingStatus.isPending,
    onSubmit: handleSubmit(handleSave),
    savingStatus,
    setIsJSON,
    title,
    jsonViewData,
  };
};

export default useExecutionSchemaForm;
