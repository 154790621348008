import { yupResolver } from '@hookform/resolvers/yup';
import { parseISO } from 'date-fns';
import { omitBy } from 'lodash';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import {
  Autocomplete,
  IconButton,
  Checkbox,
  Input,
  Notification,
  Switch,
} from 'react-ui-kit-exante';

import { JsonViewerWrapper } from '~/components/JsonViewerWrapper';

import { symbolDBService } from '../../../services/symbolDB.service';
import { checkIsValidIsoString } from '../../../utils/checkIsValidISOString';
import { formatDate } from '../../../utils/formatDate';
import { SuccessMessages, LOCALIZATION_PROPS } from '../constants';
import { getProp } from '../helpers';
import {
  Controls,
  FormContainer,
  FormPanelContainer,
  SwitchContainer,
} from '../styled';
import { IFormValues, IThemeFormProps } from '../types';

import { schema } from './ThemeForm.schema';

export const ThemeForm: FC<IThemeFormProps> = ({
  brandings,
  isNewTheme,
  localizationKeys,
  onClose,
  onDelete,
  onUpdateThemes,
  tags,
  theme,
  title = 'New theme',
}) => {
  const [isJSON, setIsJSON] = useState(false);
  const [valuesJSON, setValuesJSON] = useState<Partial<IFormValues>>(theme);

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<IFormValues>({
    defaultValues: theme,
    resolver: yupResolver(schema),
  });

  const handleDelete = useCallback(() => {
    const { _id: id } = theme;
    onDelete(id as string);
  }, [onDelete, theme]);

  const onSubmit = async (values: IFormValues) => {
    try {
      const { _id: id } = values;
      const response = isNewTheme
        ? await symbolDBService().createTheme(values)
        : await symbolDBService().updateTheme(values, id);
      if (response) {
        Notification.success({
          title: isNewTheme ? SuccessMessages.Create : SuccessMessages.Update,
        });
        onUpdateThemes();
        onClose();
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  };

  const handleOnBlur = useCallback(() => {
    const values: IFormValues = getValues();

    setValuesJSON(omitBy(values, (v) => v === ''));
  }, [getValues]);

  useEffect(() => {
    reset({ ...theme });
  }, [reset, theme]);

  const controls = useMemo(
    () => (
      <Controls>
        <IconButton
          data-test-id="themes__button--save"
          disabled={!isDirty}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={20}
          label="Save"
          type="submit"
        />
        {!isNewTheme && (
          <IconButton
            data-test-id="themes__button--delete"
            iconColor="radical"
            iconName="DeleteIcon"
            iconSize={20}
            label="Delete"
            onClick={handleDelete}
            type="button"
          />
        )}
        <IconButton
          data-test-id="themes__button--close"
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={20}
          onClick={onClose}
        />
      </Controls>
    ),
    [isDirty, isNewTheme, handleDelete, onClose],
  );

  const renderControl = (key: keyof IFormValues): ReactNode => {
    const prop = getProp(theme, key);
    const isDisabled = key.startsWith('_');

    if (typeof prop === 'object' && !Array.isArray(prop) && prop !== null) {
      return Object.keys(prop).map((k) => {
        const nestedKey = `${key}.${k}`;

        return renderControl(nestedKey as keyof IFormValues);
      });
    }

    const isLocalizedProp = LOCALIZATION_PROPS.some((name) =>
      key.includes(name),
    );

    const error = getProp(errors, key) as FieldError;

    if (isLocalizedProp) {
      return (
        <Controller<IFormValues>
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field }) => {
            const onChange = (_: any, newValue: any) =>
              field.onChange(newValue);

            const value = field.value as string;

            return (
              <Autocomplete
                data-test-id={`themes__input--${key}`}
                options={localizationKeys}
                controlProps={{
                  error: Boolean(error),
                  message: error?.message,
                  placeholder: key,
                }}
                sx={{ mt: '16px' }}
                {...field}
                onChange={onChange}
                value={value}
              />
            );
          }}
        />
      );
    }

    if (key === 'taglist') {
      return (
        <Controller<IFormValues>
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field }) => {
            const onBlur = (event: any) => {
              const value = field.value as string[];

              if (event.target.value) {
                field.onChange([...value.filter(Boolean), event.target.value]);
              }
            };

            return (
              <Autocomplete
                data-test-id={`themes__input--${key}`}
                freeSolo
                isMultiple
                clearOnBlur
                options={tags}
                {...field}
                value={field.value as string[]}
                onBlur={onBlur}
                onChange={(_e, newValue) => {
                  field.onChange(newValue);
                  handleOnBlur();
                }}
                controlProps={{
                  error: Boolean(error),
                  message: error?.message,
                  placeholder: key,
                }}
                sx={{ mt: '16px' }}
              />
            );
          }}
        />
      );
    }

    if (key === 'brandings') {
      return (
        <Controller<IFormValues>
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Autocomplete
              data-test-id={`themes__input--${key}`}
              freeSolo
              isMultiple
              options={brandings}
              {...field}
              value={field.value as string[]}
              onChange={(_e, newValue) => {
                field.onChange(newValue);
                handleOnBlur();
              }}
              controlProps={{
                error: Boolean(error),
                message: error?.message,
                placeholder: key,
              }}
              sx={{ mt: '16px' }}
            />
          )}
        />
      );
    }

    if (typeof prop === 'string' && checkIsValidIsoString(prop)) {
      const value = formatDate(parseISO(prop), true);

      return (
        <Controller
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              data-test-id={`themes__input--${key}`}
              disabled={isDisabled}
              fullWidth
              label={key}
              {...field}
              value={value}
              sx={{ mt: '16px' }}
            />
          )}
        />
      );
    }

    if (typeof prop === 'boolean') {
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          defaultValue={prop}
          render={({ field }) => (
            <Checkbox
              data-test-id={`themes__checkbox--${key}`}
              checked={field.value as boolean}
              label={key}
              {...field}
              sx={{ ml: '1px', mt: '16px' }}
            />
          )}
        />
      );
    }

    return (
      <Controller
        key={key}
        name={key}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            data-test-id={`themes__input--${key}`}
            error={Boolean(error)}
            disabled={isDisabled}
            message={error?.message}
            fullWidth
            label={key}
            {...field}
            sx={{ mt: '16px' }}
          />
        )}
      />
    );
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)} onBlur={handleOnBlur}>
      <FormPanelContainer
        action={controls}
        title={theme?.title.default || title}
      >
        {!isNewTheme && (
          <SwitchContainer isJSON={isJSON}>
            <Switch
              checked={isJSON}
              label="JSON"
              onChange={() => setIsJSON(!isJSON)}
            />
          </SwitchContainer>
        )}

        {isJSON && theme && (
          <JsonViewerWrapper
            data={valuesJSON}
            fontSize="14px"
            height="calc(100vh - 280px)"
          />
        )}

        {!isJSON && (
          <>
            {(Object.keys(theme) as Array<keyof typeof theme>).map(
              renderControl,
            )}
          </>
        )}
      </FormPanelContainer>
    </FormContainer>
  );
};
