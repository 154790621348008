import dayjs from 'dayjs';

import { InstrumentSplit } from '~/types/models';

export const getEmptyItem = (): InstrumentSplit => {
  return {
    coefficient: {},
    ignoreAutoScale: false,
    timestamp: dayjs().unix(),
  };
};
