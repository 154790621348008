import { useMemo } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import DATES from '~/constants/dates';
import {
  ScheduleForm,
  ScheduleRuleFormMatchItem,
} from '~/pages/Schedules/types';
import { CronRule } from '~/types/shared';

const getDaysString = (rule: CronRule): string => {
  if (rule.weekDay) {
    return rule.weekDay?.map((d) => DATES.weekDays.at(d - 1)).join(',');
  }

  if (rule.dayOfMonth) {
    return rule.dayOfMonth.toString();
  }

  return '*';
};

const getOccurrence = (occurrence?: string): string => {
  if (!occurrence) {
    return '';
  }

  if (occurrence === '*') {
    return ',All';
  }

  if (occurrence === 'last') {
    return ',Last';
  }

  return `,${occurrence}`;
};

const getName = (item: ScheduleRuleFormMatchItem): string => {
  const year = ['*', ''].includes(item.year) ? '*' : item.year;

  const parsedMonth = parseInt(item.month, 10);

  const month = parsedMonth ? DATES.month.at(parsedMonth - 1) : '*';

  const days = getDaysString(item.day);

  const occurrence = item.day.weekDay ? getOccurrence(item.day.occurrence) : '';

  const affix = item.isWeekDay ? `${days}${occurrence}` : item.day.dayOfMonth;

  return `${year},${month},${affix}`;
};

const useMatches = (
  form: UseFormReturn<ScheduleForm>,
  ruleIndex: number,
  id: string | null,
) => {
  const arr = form.watch(`rules.${ruleIndex}.matches`);

  const fieldControl = useFieldArray({
    name: `rules.${ruleIndex}.matches`,
    control: form.control,
  });

  const list = useMemo(() => {
    if (!arr) {
      return [];
    }

    return arr.map((item) => ({
      id: Math.random().toString(),
      name: getName(item),
    }));
  }, [arr]);

  const matchIndex = useMemo<number>(
    () => list.findIndex((item) => item.id === id),
    [list, id],
  );

  const getMatchLabel = (matchId: string) => {
    const index = list.findIndex((item) => item.id === matchId);

    return getName(form.watch(`rules.${ruleIndex}.matches.${index}`));
  };

  const handleAppend = () => {
    fieldControl.prepend([
      {
        day: { occurrence: '*', weekDay: [1] },
        month: '*',
        year: '*',
        comment: '',
        isWeekDay: true,
      },
    ]);
  };

  const handleRemove = (index: number) => {
    fieldControl.remove(index);
  };

  return {
    list,
    fieldControl,
    handleAppend,
    matchIndex,
    getMatchLabel,
    handleRemove,
  };
};

export default useMatches;
