import { yupResolver } from '@hookform/resolvers/yup';
import { orderBy, indexOf, cloneDeep } from 'lodash';
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Input,
  Panel,
  Notification,
  IconButton,
  Loader,
  Switch,
} from 'react-ui-kit-exante';

import { JsonViewerWrapper } from '~/components/JsonViewerWrapper';
import { Controls } from '~/pages/Themes/styled';
import { symbolDBService } from '~/services/symbolDB.service';

import { EDITABLE_FIELDS, FIXED_ORDER, SuccessMessages } from '../constants';
import { ISymbolTypeProps, ISymbolTypeFormValues } from '../types';

import { schema } from './SymbolType.schema';
import { Fields, FormContainer } from './styled';

export const SymbolTypeForm: FC<ISymbolTypeProps> = ({
  symbolType,
  onClose,
  onUpdateSymbolType,
  title = 'New Symbol Type',
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isJSON, setIsJSON] = useState(false);
  const [symbolTypeValues, setSymbolTypeValues] = useState(symbolType);

  const symbolTypeFormValues = useMemo<ISymbolTypeFormValues>(() => {
    const symbolTypeClone = cloneDeep(symbolType);

    delete symbolTypeClone.brandingDisplayName;
    delete symbolTypeClone.eventId;
    delete symbolTypeClone.treePath;

    return symbolTypeClone;
  }, [symbolType]);

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    reset,
  } = useForm<ISymbolTypeFormValues>({
    defaultValues: symbolTypeFormValues,
    resolver: yupResolver(schema),
  });

  const handleOnBlur = useCallback(() => {
    const values = getValues();

    setSymbolTypeValues({ ...symbolType, ...values });
  }, []);

  useEffect(() => {
    reset({ ...symbolTypeFormValues });
  }, [symbolTypeFormValues, reset]);

  const controls = useMemo(
    () => (
      <Controls>
        <Switch
          checked={isJSON}
          label="JSON"
          onChange={() => setIsJSON(!isJSON)}
        />
        <IconButton
          data-test-id="symbol-type__button--save"
          disabled={!isDirty}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={24}
          label="Save"
          type="submit"
        />
        <IconButton
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={24}
          onClick={onClose}
        />
      </Controls>
    ),
    [isDirty, isJSON, onClose],
  );

  const onSubmit = async (values: ISymbolTypeFormValues) => {
    try {
      setIsLoading(true);

      const response = await symbolDBService().updateSymbolType(values);

      if (response) {
        Notification.success({ title: SuccessMessages.Update });
        onUpdateSymbolType();
        onClose();
      }
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderControl = (key: keyof ISymbolTypeFormValues): ReactNode => {
    const isDisabled = !EDITABLE_FIELDS.includes(key);

    return (
      <Controller
        key={key}
        name={key}
        control={control}
        defaultValue=""
        render={({ field }) => {
          const handleOnChangeNumber = (
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            onChange: (value: number) => void,
          ) => {
            onChange(
              Number.isNaN(Number(event.target.value))
                ? 0
                : Number(event.target.value),
            );
          };

          return (
            <Input
              disabled={isDisabled}
              error={Boolean(errors[key])}
              fullWidth
              label={key}
              {...field}
              onChange={(event) => {
                if (key === 'expiredTtl') {
                  return handleOnChangeNumber(event, field.onChange);
                }

                return field.onChange(event);
              }}
              sx={{ mt: '16px', width: '50%' }}
            />
          );
        }}
      />
    );
  };

  const orderedFields = useMemo(() => {
    return orderBy(
      Object.keys(symbolTypeFormValues),
      (name) => indexOf(FIXED_ORDER, name),
      ['desc'],
    );
  }, [symbolTypeFormValues]);

  return !isLoading ? (
    <FormContainer onSubmit={handleSubmit(onSubmit)} onBlur={handleOnBlur}>
      <Panel title={symbolTypeValues?.name || title} action={controls}>
        {isJSON && symbolTypeValues && (
          <JsonViewerWrapper
            data={symbolTypeValues}
            fontSize="14px"
            height="calc(100vh - 220px)"
          />
        )}

        {!isJSON && (
          <Fields>
            {(orderedFields as Array<keyof typeof symbolTypeFormValues>).map(
              renderControl,
            )}
          </Fields>
        )}
      </Panel>
    </FormContainer>
  ) : (
    <Loader size="l" isCentered />
  );
};
