import { useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { GlobalStyles, NotificationProvider } from 'react-ui-kit-exante';

import {
  Brokers,
  Currencies,
  CurrencyHolidays,
  Exchanges,
  ExecutionSchemes,
  Feeds,
  Instruments,
  Localizations,
  Reporting,
  Schedules,
  SymbolTypes,
  Themes,
  MainWrapper,
} from '~/pages';
import { Event } from '~/pages/StaticData/Main/Event/Event';
import { Mapping } from '~/pages/StaticData/Mapping/Mapping';
import { MappingCreate } from '~/pages/StaticData/Mapping/MappingCreate/MappingCreate';
import { MappingForm } from '~/pages/StaticData/Mapping/MappingEdit/MappingForm';
import { ROUTES } from '~/pages/routing';
import { getBaseName } from '~/utils/getBaseName';

import { ErrorFallback } from '../ErrorFallback';
import { Navigation } from '../Navigation/Navigation';

import { Container, RoutesContainer } from './styled';

import '~/styles/Global.css';

export const Application = () => {
  const history = window.runUIhistoryContainer;
  const [navigator, setNavigator] = useState({
    action: history?.action,
    location: history?.location,
  });

  useLayoutEffect(() => {
    if (history) {
      history.listen(() => {
        return setNavigator({
          action: history.action,
          location: history.location,
        });
      });
    }
  }, [history]);

  return (
    <>
      <NotificationProvider duration={10000} />
      {window.runUIhistoryContainer ? (
        <Router
          location={navigator.location}
          navigationType={navigator.action}
          navigator={history}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GlobalStyles />
            <Routes>
              <Route path={ROUTES.THEMES} element={<Themes />} />
              <Route path={ROUTES.INSTRUMENTS} element={<Instruments />} />
              <Route path={ROUTES.LOCALIZATIONS} element={<Localizations />} />
              <Route path={ROUTES.MAPPING} element={<Mapping />} />
              <Route
                path={ROUTES.NEW_MAPPING_CONFIG}
                element={<MappingCreate />}
              />
              <Route path={ROUTES.MAPPING_CONFIG} element={<MappingForm />} />
              <Route
                path={ROUTES.CURRENCY_HOLIDAYS}
                element={<CurrencyHolidays />}
              />
              <Route path={ROUTES.REPORTING} element={<Reporting />} />
              <Route path={ROUTES.STATIC_DATA} element={<MainWrapper />} />
              <Route path={ROUTES.STATIC_DATA_EVENT} element={<Event />} />
              <Route path={ROUTES.SYMBOL_TYPES} element={<SymbolTypes />} />
              <Route path={ROUTES.CURRENCIES} element={<Currencies />} />
              <Route path={ROUTES.FEEDS} element={<Feeds />} />
              <Route path={ROUTES.SCHEDULES} element={<Schedules />} />
              <Route path={ROUTES.EXCHANGES} element={<Exchanges />} />
              <Route
                path={ROUTES.EXECUTION_SCHEMES}
                element={<ExecutionSchemes />}
              />
              <Route path={ROUTES.BROKERS} element={<Brokers />} />
            </Routes>
          </ErrorBoundary>
        </Router>
      ) : (
        <BrowserRouter basename={getBaseName()}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Container>
              <GlobalStyles />
              <Navigation />
              <RoutesContainer>
                <Routes>
                  <Route path={ROUTES.THEMES} element={<Themes />} />
                  <Route path={ROUTES.INSTRUMENTS} element={<Instruments />} />
                  <Route
                    path={ROUTES.LOCALIZATIONS}
                    element={<Localizations />}
                  />
                  <Route path={ROUTES.REPORTING} element={<Reporting />} />
                  <Route path={ROUTES.MAPPING} element={<Mapping />} />
                  <Route path={ROUTES.SYMBOL_TYPES} element={<SymbolTypes />} />
                  <Route path={ROUTES.CURRENCIES} element={<Currencies />} />
                  <Route path={ROUTES.FEEDS} element={<Feeds />} />
                  <Route path={ROUTES.SCHEDULES} element={<Schedules />} />
                  <Route path={ROUTES.EXCHANGES} element={<Exchanges />} />
                  <Route
                    path={ROUTES.EXECUTION_SCHEMES}
                    element={<ExecutionSchemes />}
                  />
                  <Route path={ROUTES.BROKERS} element={<Brokers />} />
                  <Route
                    path={ROUTES.NEW_MAPPING_CONFIG}
                    element={<MappingCreate />}
                  />
                  <Route
                    path={ROUTES.MAPPING_CONFIG}
                    element={<MappingForm />}
                  />
                  <Route
                    path={ROUTES.CURRENCY_HOLIDAYS}
                    element={<CurrencyHolidays />}
                  />
                  <Route path={ROUTES.STATIC_DATA} element={<MainWrapper />} />
                  <Route path={ROUTES.STATIC_DATA_EVENT} element={<Event />} />
                </Routes>
              </RoutesContainer>
            </Container>
          </ErrorBoundary>
        </BrowserRouter>
      )}
    </>
  );
};
