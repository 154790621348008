import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Input, Switch, Select, IconButton } from 'react-ui-kit-exante';

import { SortableList } from '~/components/SortableList';

import styles from './MatchesForm.module.css';
import useMatches from './hooks/useMatches';
import useOptions from './hooks/useOptions';
import {
  Controls,
  Placeholder,
  SubRow,
  ToggleButtonGroupContainer,
} from './styled';
import { MatchesFormProps } from './types';

const MatchesForm: FC<MatchesFormProps> = ({ form, ruleIndex }) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const { monthOptions, weekDaysOptions, occurrenceOptions } = useOptions();
  const {
    list,
    fieldControl,
    handleAppend,
    matchIndex,
    getMatchLabel,
    handleRemove,
  } = useMatches(form, ruleIndex, selectedId);

  useEffect(() => {
    if (list.length > 0) {
      setSelectedId(list[0].id);
    }
  }, [list]);

  const isWeekDay = form.watch(
    `rules.${ruleIndex}.matches.${matchIndex}.isWeekDay`,
  );

  const weekDay = form.watch(
    `rules.${ruleIndex}.matches.${matchIndex}.day.weekDay`,
  );

  const occurrence = form.watch(
    `rules.${ruleIndex}.matches.${matchIndex}.day.occurrence`,
  );

  const dayOfMonth = form.watch(
    `rules.${ruleIndex}.matches.${matchIndex}.day.dayOfMonth`,
  );

  const validateYearValue = (value: string): boolean => {
    if (value === '*' || value === '') {
      return false;
    }

    const parsedValue = parseInt(value, 10);

    if (!/^(?:\d{4}|)$/.test(value)) {
      return true;
    }

    return !(parsedValue <= 9999 && parsedValue >= 2000);
  };

  useEffect(() => {
    if (isWeekDay && (!weekDay || !occurrence)) {
      form.setValue(`rules.${ruleIndex}.matches.${matchIndex}.day.weekDay`, [
        1,
      ]);

      form.setValue(
        `rules.${ruleIndex}.matches.${matchIndex}.day.occurrence`,
        '*',
      );
    } else if (!dayOfMonth) {
      form.setValue(
        `rules.${ruleIndex}.matches.${matchIndex}.day.dayOfMonth`,
        1,
      );
    }
  }, [isWeekDay]);

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          label="Add match"
          onClick={handleAppend}
        />
      </div>
      {list.length === 0 && <Placeholder>This rule has no matches</Placeholder>}
      {list.length > 0 && (
        <div className={styles.Form}>
          <SortableList
            items={list}
            selectedId={selectedId}
            onSelect={setSelectedId}
            onOrderChange={fieldControl.move}
            getLabel={getMatchLabel}
          />

          <Controls>
            <div className={styles.Row}>
              <Controller
                key={`rules.${ruleIndex}.matches.${matchIndex}.year`}
                name={`rules.${ruleIndex}.matches.${matchIndex}.year`}
                control={form.control}
                render={({ field }) => (
                  <Input
                    label="Year (2000 - 9999)"
                    sx={{ width: '100%' }}
                    error={validateYearValue(field.value)}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Any"
                    message={
                      validateYearValue(field.value)
                        ? 'Year value is invalid'
                        : ''
                    }
                  />
                )}
              />

              <Controller
                key={`rules.${ruleIndex}.matches.${matchIndex}.month`}
                name={`rules.${ruleIndex}.matches.${matchIndex}.month`}
                control={form.control}
                render={({ field, fieldState }) => (
                  <Select
                    options={monthOptions}
                    label="Month"
                    sx={{ width: '100%' }}
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <SubRow>
              <Controller
                key={`rules.${ruleIndex}.matches.${matchIndex}.isWeekDay`}
                name={`rules.${ruleIndex}.matches.${matchIndex}.isWeekDay`}
                control={form.control}
                render={({ field }) => (
                  <Switch
                    checked={isWeekDay}
                    label="Weekday"
                    onChange={field.onChange}
                  />
                )}
              />
              {!isWeekDay && (
                <Controller
                  key={`rules.${ruleIndex}.matches.${matchIndex}.day.dayOfMonth`}
                  name={`rules.${ruleIndex}.matches.${matchIndex}.day.dayOfMonth`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Input
                      maxLength={2}
                      label="Day of month"
                      className={styles.DayOfMonth}
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Any"
                      message={fieldState.error?.message}
                    />
                  )}
                />
              )}
              {isWeekDay && (
                <div className={styles.Days}>
                  <Controller
                    key={`rules.${ruleIndex}.matches.${matchIndex}.day.occurrence`}
                    name={`rules.${ruleIndex}.matches.${matchIndex}.day.occurrence`}
                    control={form.control}
                    render={({ field }) => (
                      <Select
                        label="Occurrence"
                        sx={{ width: '130px' }}
                        options={occurrenceOptions}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    key={`rules.${ruleIndex}.matches.${matchIndex}.day.weekDay`}
                    name={`rules.${ruleIndex}.matches.${matchIndex}.day.weekDay`}
                    control={form.control}
                    render={({ field }) => (
                      <ToggleButtonGroupContainer
                        classes={{
                          groupedHorizontal: styles.ToggleButtonGroupHorizontal,
                        }}
                        onChange={field.onChange}
                        options={weekDaysOptions}
                        value={(field.value as any) || []}
                      />
                    )}
                  />
                </div>
              )}
            </SubRow>

            <div className={styles.Comment}>
              <Controller
                key={`rules.${ruleIndex}.matches.${matchIndex}.comment`}
                name={`rules.${ruleIndex}.matches.${matchIndex}.comment`}
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    label="Comment"
                    sx={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={20}
              onClick={() => handleRemove(matchIndex)}
              style={{
                justifyContent: 'flex-start',
                marginRight: '5px',
              }}
            />
          </Controls>
        </div>
      )}
    </div>
  );
};

export default MatchesForm;

/*

 */
