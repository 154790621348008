import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { SortableListItem } from '~/components/SortableList/types';
import useFetchStatus from '~/hooks/useFetchStatus';
import { IFeedProvider } from '~/pages/Feeds/types';
import { symbolDBService } from '~/services/symbolDB.service';
import { getUUIDKey } from '~/utils/uuid';

import { FeedRouteParams, FormValues } from '../types';
import { getFormValues, mapGateways } from '../utils';
import validationSchema from '../validation';

const useFeedForm = (
  feeds: Array<IFeedProvider>,
  onSubmit: (payload: IFeedProvider) => void,
  onDelete?: (id: string) => void,
  onError?: () => void,
) => {
  const { id } = useParams<FeedRouteParams>();

  const [savingStatus, savingStatusActions] = useFetchStatus();
  const [deletingStatus, deletingStatusActions] = useFetchStatus();

  const feed = useMemo(
    () => feeds.find((item) => item._id === id),
    [feeds, id],
  );

  const [isJSON, setIsJSON] = useState(false);

  const isNew = !feed?._id;
  const defaultTitle = feed?.name || 'Unnamed Feed';
  const title = isNew ? 'New Feed' : defaultTitle;

  const form = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const values = form.getValues();
  const jsonViewData = useMemo(() => {
    if (!id || !feed) {
      return {};
    }

    return {
      name: values.name,
      providerType: values.providerType || undefined,
      gateways: mapGateways(values.gateways),
    };
  }, [values, id, feed]);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form;

  const gatewaysArray = form.getValues('gateways');
  const gatewaysFieldControl = useFieldArray({ name: 'gateways', control });

  const gatewaysList = useMemo<Array<SortableListItem>>(() => {
    if (!gatewaysArray) {
      return [];
    }

    return gatewaysArray.map((gateway) => ({
      name: gateway.name,
      id: gateway.key,
    }));
  }, [gatewaysArray]);

  const handleAppendGateway = () => {
    const index = gatewaysFieldControl.fields.length + 1;

    gatewaysFieldControl.prepend([
      {
        key: getUUIDKey(),
        name: index < 2 ? `New` : `New ${index}`,
        source: '',
        address: '',
        environment: '',
        delayed: '0',
        description: '',
      },
    ]);
  };

  const handleSave = async (formValues: FormValues) => {
    const payload = {
      name: formValues.name,
      providerType: formValues.providerType || undefined,
      gateways: mapGateways(formValues.gateways),
    };

    savingStatusActions.handleStart();

    try {
      const data = id
        ? await symbolDBService().updateFeedProvider(id, payload)
        : await symbolDBService().createFeedProvider(payload);

      form.reset();
      onSubmit(data);
      savingStatusActions.handleSuccess();
      Notification.success({ title: 'Feed saved' });
    } catch (e: unknown) {
      if (onError) {
        onError();
      }

      const isExistError =
        (e as AxiosError).response?.data?.description?.name ===
        'already exists';

      if (isExistError) {
        Notification.error({ title: 'Feed with this name already exist' });
      }

      savingStatusActions.handleError(e as AxiosError, { quiet: isExistError });
    }
  };

  const handleDeleteFeed = async () => {
    if (id && onDelete) {
      try {
        deletingStatusActions.handleStart();
        await symbolDBService().deleteFeedProvider(id);

        onDelete(id);
        deletingStatusActions.handleSuccess();
      } catch (e: unknown) {
        deletingStatusActions.handleError(e as AxiosError);

        if (onError) {
          onError();
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      const initialState = feeds.find((item) => item._id === id);

      reset(getFormValues(initialState));
    }
  }, [feeds, id]);

  return {
    deletingStatus,
    feed,
    form,
    gatewaysArray,
    gatewaysFieldControl,
    gatewaysList,
    handleAppendGateway,
    handleDeleteFeed,
    id,
    isDirty,
    isJSON,
    isNew,
    isSaveDisabled: !isDirty || savingStatus.isPending,
    onSubmit: handleSubmit(handleSave),
    savingStatus,
    setIsJSON,
    title,
    jsonViewData,
  };
};

export default useFeedForm;
