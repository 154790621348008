import {
  useState,
  MouseEventHandler,
  ChangeEventHandler,
  useMemo,
  useCallback,
} from 'react';
import {
  DatePicker,
  Notification,
  Panel,
  Select,
  IconButton,
} from 'react-ui-kit-exante';

import { today } from '~/constants/shared';
import { CURRENCIES } from '~/services/constants';
import { showErrorNotification } from '~/utils/showErrorNotification';

import { FIELDS_WIDTH } from '../constants';
import { formatDate } from '../helpers';
import { useActiveCurrency } from '../hooks/useActiveCurrency';
import { useAddCurrencyHolidays } from '../hooks/useAddCurrencyHoliday';
import { Currency } from '../types';

import { CurrencyHolidayFormProps } from './CurrencyHolidayForm.types';
import { Actions, InputContainer } from './styled';

export const CurrencyHolidaysForm = ({
  open,
  onClose,
  refresh,
  onChangeActiveCurrency,
}: CurrencyHolidayFormProps) => {
  const [date, setDate] = useState(today);
  const [dateError, setDateError] = useState<string | null>(null);
  const { activeCurrency: initialCurrency } = useActiveCurrency();
  const [currency, setCurrency] = useState<Currency>(
    initialCurrency || CURRENCIES[0],
  );

  const { addCurrencyHoliday, loading: savePending } = useAddCurrencyHolidays();

  const isReadyForSubmit = currency && date && !dateError && !savePending;

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();

      const formattedDate = formatDate(date);
      addCurrencyHoliday(
        { date: formattedDate, currency },
        showErrorNotification,
        () => refresh(currency),
        () => Notification.success({ title: 'Successfully added' }),
      );
    },
    [addCurrencyHoliday, currency, date, refresh],
  );

  const handleDateError = (err: unknown) => {
    if (err === 'invalidDate') {
      setDateError('Valid date format is mm/dd/yyyy');
    } else if (err === 'minDate') {
      setDateError('Minimum date is 01.01.1900');
    } else if (err === 'maxDate') {
      setDateError('Maximum date is 31.12.2099');
    } else if (err === null || typeof err !== 'string') {
      setDateError(null);
    } else {
      setDateError(err);
    }
  };

  const handleChangeSelect: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value as Currency;
    setCurrency(value);
    onChangeActiveCurrency(value);
  };

  const selectCurrencyOptions = CURRENCIES.map((currencyItem) => ({
    value: currencyItem,
    label: currencyItem,
  }));

  const handleChangeDate = (value: unknown) => {
    if (value instanceof Date) {
      setDate(value);
    }
  };

  const panelActions = useMemo(
    () => (
      <Actions>
        <IconButton
          data-test-id="currency-holidays__button--save"
          disabled={!isReadyForSubmit}
          iconName="SaveIcon"
          type="submit"
          label="Save"
          onClick={handleSubmit}
          className="SaveButton"
        />
        <IconButton
          data-test-id="currency-holidays__button--close"
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={24}
          onClick={onClose}
        />
      </Actions>
    ),
    [isReadyForSubmit, handleSubmit, onClose, savePending],
  );

  if (!open) {
    return null;
  }

  return (
    <Panel title="Currency Holiday" action={panelActions}>
      <InputContainer>
        <Select
          placeholder="Currency"
          required
          sx={FIELDS_WIDTH}
          label="Currency"
          value={currency}
          onChange={handleChangeSelect}
          options={selectCurrencyOptions}
        />
      </InputContainer>
      <InputContainer>
        <DatePicker
          required
          label="Date *"
          onChange={handleChangeDate}
          value={date}
          onError={handleDateError}
          controlProps={{
            error: Boolean(dateError),
            message: dateError || '',
            sx: FIELDS_WIDTH,
          }}
        />
      </InputContainer>
    </Panel>
  );
};
