import { FC, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Input,
  Checkbox,
  Tab,
  TabPanel,
  IconButton,
} from 'react-ui-kit-exante';

import { SortableList } from '~/components/SortableList';

import { MatchesForm } from '../MatchesForm';
import { ScheduleSectionForm } from '../ScheduleSectionForm';

import { useRules } from './hooks';
import { Container, Form, Placeholder, Row, TabsContainer } from './styled';
import { RulesFormProps } from './types';

const RulesForm: FC<RulesFormProps> = ({ form }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { rulesList, rulesFieldControl, getRuleName } = useRules(form);

  const selectedIndex = useMemo<number>(
    () => rulesList.findIndex((item) => item.id === selectedId),
    [rulesList, selectedId],
  );

  useEffect(() => {
    if (rulesList.length > 0) {
      setSelectedId(rulesList[0].id);
    }
  }, [rulesList]);

  return (
    <>
      {rulesList.length === 0 && (
        <Placeholder>This schedule has no rules</Placeholder>
      )}
      {rulesList.length > 0 && (
        <Container>
          <SortableList
            items={rulesList}
            selectedId={selectedId}
            onSelect={setSelectedId}
            onOrderChange={rulesFieldControl.move}
            getLabel={getRuleName}
          />
          <Form>
            <Controller
              key={`rules.${selectedIndex}.description`}
              name={`rules.${selectedIndex}.description`}
              control={form.control}
              render={({ field }) => (
                <Input
                  label="Name"
                  sx={{ width: '100%' }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Row>
              <Controller
                key={`rules.${selectedIndex}.mergeNext`}
                name={`rules.${selectedIndex}.mergeNext`}
                control={form.control}
                render={({ field }) => (
                  <Checkbox
                    label="Merge next session with previous"
                    checked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <IconButton
                iconColor="radical"
                iconName="DeleteIcon"
                iconSize={20}
                onClick={() => rulesFieldControl.remove(selectedIndex)}
                style={{ marginRight: '5px' }}
              />
            </Row>

            <TabsContainer
              value={selectedTab}
              onChange={(e, index) => setSelectedTab(index)}
            >
              <Tab label="Matches" />
              <Tab label="Schedule" />
            </TabsContainer>
            <TabPanel value={selectedTab} index={0}>
              <MatchesForm form={form} ruleIndex={selectedIndex} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <ScheduleSectionForm form={form} ruleIndex={selectedIndex} />
            </TabPanel>
          </Form>
        </Container>
      )}
    </>
  );
};

export default RulesForm;
