import { InstrumentState } from '../types';

const onSaveSucceed = (state: InstrumentState): InstrumentState => ({
  ...state,

  dirty: false,
  ref: state.values,

  saveStatus: {
    succeed: true,
    pending: false,
    error: false,
  },
});

export default onSaveSucceed;
