import { ExchangeItem } from '~/types/models';

import { defaultValues } from './constants';
import {
  ExchangeFormItem,
  ExchangeFormValue,
  ExchangeSectionItem,
} from './types';

export const mapEntitiesName = (
  entities?: Record<string, { name: string }>,
): Array<ExchangeFormItem> => {
  if (!entities) {
    return [];
  }

  return Object.keys(entities).map((id) => ({
    id,
    brokerId: id || '',
    override: entities[id].name || '',
  }));
};

export const mapSections = (
  entities?: ExchangeItem['sections'],
): Array<ExchangeSectionItem> => {
  if (!entities) {
    return [];
  }

  return Object.keys(entities).map((id) => ({
    id,
    name: entities[id].name || '',
    description: entities[id].description || '',
    scheduleId: entities[id].scheduleId || '',
    submarket: entities[id].submarket || '',
    brokers: mapEntitiesName(entities[id].brokerNames),
    feeds: mapEntitiesName(entities[id].feedNames),
  }));
};

export const mapFormItems = (
  items: Array<ExchangeFormItem>,
): Record<string, { name: string }> => {
  return items.reduce<Record<string, { name: string }>>((result, item) => {
    if (!item.brokerId) {
      return result;
    }

    return {
      ...result,
      [item.brokerId]: { name: item.override },
    };
  }, {});
};

export const mapFormSectionItems = (
  items: Array<ExchangeSectionItem>,
): ExchangeItem['sections'] => {
  return items.reduce<ExchangeItem['sections']>((result, { id, ...rest }) => {
    if (!id) {
      return result;
    }

    return {
      ...result,
      [id]: {
        name: rest.name,
        ...(rest.description && { description: rest.description }),
        ...(rest.scheduleId && { scheduleId: rest.scheduleId }),
        ...(rest.submarket && { submarket: rest.submarket }),
        ...(rest.feeds.length > 0 && { feedNames: mapFormItems(rest.feeds) }),
        ...(rest.brokers.length > 0 && {
          brokerNames: mapFormItems(rest.brokers),
        }),
      },
    };
  }, {});
};

export const mapIdentifiersToPayload = (
  figi: string,
  mic: string,
): ExchangeItem['identifiers'] | null => {
  if (!figi?.length && !mic?.length) {
    return null;
  }

  return { FIGI: figi, MIC: mic };
};

export const mapIdentifiersToPayloadForJsonViewer = (
  figi: string,
  mic: string,
): Partial<ExchangeItem['identifiers']> | null => {
  if (!figi.length && !mic.length) {
    return null;
  }

  return {
    ...(figi && { FIGI: figi }),
    ...(mic && { MIC: mic }),
  };
};

export const getFormValue = (
  payload: ExchangeItem | null,
): ExchangeFormValue => {
  if (!payload) {
    return defaultValues;
  }

  return {
    name: payload.name || defaultValues.name,
    exchangeName: payload.exchangeName || payload.exchangeName,
    treeName: payload.treeName || defaultValues.treeName,
    description: payload.description || defaultValues.description,
    figi: payload.identifiers?.FIGI || defaultValues.figi,
    mic: payload.identifiers?.MIC || defaultValues.mic,
    feeds: mapEntitiesName(payload.feedNames),
    brokers: mapEntitiesName(payload.brokerNames),
    sections: mapSections(payload.sections),
  };
};

export const mapFormValueToPayload = (
  value: ExchangeFormValue,
): Partial<ExchangeItem> => {
  const identifiers = mapIdentifiersToPayload(value.figi, value.mic);

  return {
    name: value.name,
    exchangeName: value.exchangeName,

    ...(value.description && { description: value.description }),
    ...(value.treeName && { treeName: value.treeName }),
    ...(identifiers && { identifiers }),

    ...(value.brokers.length > 0 && {
      brokerNames: mapFormItems(value.brokers),
    }),

    ...(value.feeds.length > 0 && { feedNames: mapFormItems(value.feeds) }),

    ...(value.sections.length > 0 && {
      sections: mapFormSectionItems(value.sections),
    }),
  };
};
