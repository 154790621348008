import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Input, Select } from 'react-ui-kit-exante';

import {
  DeleteButton,
  List,
  ListBody,
  ListForm,
  ListFormRow,
  ListHeader,
  ListTitle,
} from '../../styled';
import { ListFormPlaceholder } from '../ListFormPlaceholder';

import { TBrokersFormProps } from './types';

export const BrokersForm: FC<TBrokersFormProps> = ({
  addBroker,
  brokersArray,
  brokersFieldControl,
  brokersOptions,
  fieldName = 'brokers',
  form,
}) => {
  return (
    <List>
      <ListHeader>
        <ListTitle>Brokers</ListTitle>
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          label="Add Broker"
          onClick={addBroker}
        />
      </ListHeader>

      {brokersArray?.length === 0 && <ListFormPlaceholder name="brokers" />}

      {brokersArray?.length > 0 && (
        <ListBody>
          <ListForm>
            {brokersArray.map((broker, index) => (
              <ListFormRow key={broker.id}>
                <Controller
                  key={`${fieldName}.${index}.brokerId`}
                  name={`${fieldName}.${index}.brokerId`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Select
                      options={brokersOptions}
                      label="Broker *"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  key={`${fieldName}.${index}.override`}
                  name={`${fieldName}.${index}.override`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Input
                      label="Broker Name Override"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />

                <DeleteButton>
                  <IconButton
                    iconColor="radical"
                    iconName="DeleteIcon"
                    iconSize={20}
                    onClick={() => brokersFieldControl.remove(index)}
                    style={{ marginRight: '5px' }}
                  />
                </DeleteButton>
              </ListFormRow>
            ))}
          </ListForm>
        </ListBody>
      )}
    </List>
  );
};
