import ReactJson, { ReactJsonViewProps } from 'react-json-view';

import { DefaultThemeProvider } from 'theme';

import { DEFAULT_JSON_VIEWER_PARAMS } from './constants';

export const JsonViewer = ({ src, ...props }: ReactJsonViewProps) => {
  return (
    <DefaultThemeProvider>
      <ReactJson {...DEFAULT_JSON_VIEWER_PARAMS} src={src} {...props} />
    </DefaultThemeProvider>
  );
};
