import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Input, Select } from 'react-ui-kit-exante';

import styles from './MainForm.module.css';
import { useOptions } from './hooks';
import { MainFormProps } from './types';

const MainForm: FC<MainFormProps> = ({ form }) => {
  const { control } = form;
  const { timezones } = useOptions();

  return (
    <div className={styles.Container}>
      <Controller
        key="name"
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <Input
            required
            label="Name"
            sx={{ width: '100%' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={fieldState.error?.message}
          />
        )}
      />

      <Controller
        key="timezone"
        name="timezone"
        control={control}
        render={({ field, fieldState }) => (
          <Select
            required
            options={timezones}
            label="Timezone"
            sx={{ width: '100%' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={fieldState.error?.message}
          />
        )}
      />

      <Controller
        key="description"
        name="description"
        control={control}
        render={({ field }) => (
          <Input
            label="Description"
            sx={{ width: '100%' }}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
};

export default MainForm;
