import { useCallback, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { TreeView, Skeleton, Loader, useTheme } from 'react-ui-kit-exante';

import { ReactComponent as DownloadingIcon } from '~/assets/i/Downloading.svg';
import { ReactComponent as FolderIcon } from '~/assets/i/Folder.svg';
import { ReactComponent as FolderOpenIcon } from '~/assets/i/FolderOpen.svg';
import { ReactComponent as SyncIcon } from '~/assets/i/Sync.svg';

import { UnifiedTreeItem } from '../../types';

import {
  TreeContainer,
  TreeItemContainer,
  LoadingContainer,
  LoadingItem,
  FetchMoreButton,
  Label,
  SyncIconContainer,
} from './styled';
import { TreeProps } from './types';
import { getColor, getItemIcon, getChildNodesAbstract } from './utils';

const Tree = ({
  data,
  expanded,
  hideInstrumentIcons,
  loading,
  onSelectNode,
  onFetchMore,
  onFetchNext,
  onFetchPrevious,
  searchText,
  selected,
  showSyncOnSchedule,
}: TreeProps) => {
  const theme = useTheme();

  const renderTree = useCallback(
    (item: UnifiedTreeItem) => {
      const {
        trading,
        abstract,
        childOffset,
        childTotal,
        expiry,
        fetchMorePageLoading,
        fetchMorePage,
        fetchNext,
        fetchNextLoading,
        fetchPrevious,
        fetchPreviousLoading,
        id,
        isLoading,
        isPlaceholder,
        name,
        nodes,
        syncOnSchedule,
      } = item;

      /**
       * Do not render instruments if property hideInstrumentIcons is true
       */
      if (hideInstrumentIcons && !abstract) {
        return null;
      }

      const color = getColor(theme, trading);
      const EndIcon = getItemIcon(isLoading, abstract);
      const isChildNodeAbstract = getChildNodesAbstract(nodes);

      if (!fetchPrevious && name === 'Load Previous') {
        return null;
      }

      if (fetchMorePageLoading) {
        return (
          <FetchMoreButton type="button" key={`${id}-page-loading`}>
            <Loader />
            <span>{name}</span>
          </FetchMoreButton>
        );
      }

      if (typeof fetchMorePage === 'number') {
        return (
          <FetchMoreButton
            key={`${id}-page`}
            type="button"
            onClick={() => onFetchMore(id, fetchMorePage)}
          >
            <DownloadingIcon />
            <span>{name}</span>
          </FetchMoreButton>
        );
      }

      if (fetchPreviousLoading) {
        return (
          <FetchMoreButton type="button" key={`${id}-previous-loading`}>
            <Loader />
            <span>{name}</span>
          </FetchMoreButton>
        );
      }

      if (
        fetchPrevious &&
        typeof childOffset === 'number' &&
        typeof childTotal === 'number'
      ) {
        return (
          <FetchMoreButton
            key={`${id}-previous`}
            type="button"
            onClick={() => onFetchPrevious(id, childOffset, childTotal)}
          >
            <DownloadingIcon />
            <span>{name}</span>
          </FetchMoreButton>
        );
      }

      if (fetchNextLoading) {
        return (
          <FetchMoreButton type="button" key={`${id}-next-loading`}>
            <Loader />
            <span>{name}</span>
          </FetchMoreButton>
        );
      }

      if (
        fetchNext &&
        typeof childOffset === 'number' &&
        typeof childTotal === 'number'
      ) {
        return (
          <FetchMoreButton
            key={`${id}-next`}
            type="button"
            onClick={() => onFetchNext(id, childOffset, childTotal)}
          >
            <DownloadingIcon />
            <span>{name}</span>
          </FetchMoreButton>
        );
      }

      return (
        <TreeItemContainer
          key={id}
          nodeId={id}
          label={
            <Label expiry={expiry}>
              <Highlighter searchWords={[searchText]} textToHighlight={name} />
              {showSyncOnSchedule && Boolean(syncOnSchedule) && (
                <SyncIconContainer>
                  <SyncIcon />
                </SyncIconContainer>
              )}
            </Label>
          }
          endIcon={!isPlaceholder && <EndIcon style={{ color }} />}
          expandIcon={<FolderIcon style={{ color }} />}
          collapseIcon={
            hideInstrumentIcons && !isChildNodeAbstract ? (
              <FolderIcon style={{ color }} />
            ) : (
              <FolderOpenIcon style={{ color }} />
            )
          }
        >
          {nodes.map(renderTree)}
        </TreeItemContainer>
      );
    },
    [searchText, expanded],
  );

  const { current: preloader } = useRef(
    <LoadingContainer>
      {Array.from({ length: 23 }, () => Math.random()).map((key) => (
        <LoadingItem key={key}>
          <Skeleton variant="text" width={25} height={30} />
          <Skeleton variant="text" width={120} height={30} />
        </LoadingItem>
      ))}
    </LoadingContainer>,
  );

  if (loading) {
    return preloader;
  }

  return (
    <TreeContainer>
      {data.map((root) => (
        <TreeView
          selected={selected}
          expanded={expanded}
          key={root.id}
          aria-label="rich object"
          onNodeSelect={onSelectNode}
        >
          {renderTree(root)}
        </TreeView>
      ))}
    </TreeContainer>
  );
};

export default Tree;
