import { IFeedGateway, IFeedProvider } from '../../types';

import { FormValues, GatewayValues } from './types';

const defaultValues: FormValues = {
  name: '',
  providerType: '',
  gateways: [],
};

const plainGateways = (
  gateways: Record<string, IFeedGateway> | null,
): Array<GatewayValues> => {
  if (!gateways) {
    return [];
  }

  return Object.keys(gateways).map((key) => ({
    key,
    name: gateways[key].name,
    source: gateways[key].feedSource,
    address: gateways[key].feedAddress,
    environment: gateways[key].environment,
    delayed: gateways[key].delayedFeed ? '1' : '0',
    description: gateways[key].description,
    capacity: gateways[key].capacity,
  }));
};

export const mapGateways = (
  gateways?: Array<GatewayValues>,
): Record<string, IFeedGateway> | undefined => {
  if (!gateways || gateways.length === 0) {
    return undefined;
  }

  return gateways.reduce<Record<string, IFeedGateway>>((result, gateway) => {
    return {
      ...result,
      [gateway.key]: {
        delayedFeed: gateway.delayed === '1',
        environment: gateway.environment,
        feedAddress: gateway.address,
        feedSource: gateway.source,
        name: gateway.name,
        description: gateway.description || undefined,
        capacity: gateway.capacity || undefined,
      },
    };
  }, {});
};

export const mapGatewaysForJsonViewer = (
  gateways?: Array<GatewayValues>,
): Record<string, IFeedGateway> => {
  if (!gateways || gateways.length === 0) {
    return {};
  }

  return gateways.reduce<Record<string, IFeedGateway>>((result, gateway) => {
    return {
      ...result,
      [gateway.key]: {
        delayedFeed: !!gateway.delayed,
        environment: gateway.environment,
        feedAddress: gateway.address,
        feedSource: gateway.source,
        name: gateway.name,
        ...(gateway.capacity && { capacity: gateway.capacity }),
        ...(gateway.description && { description: gateway.description }),
      },
    };
  }, {});
};

export const getFormValues = (feed?: IFeedProvider): FormValues => {
  if (!feed) {
    return defaultValues;
  }

  return {
    name: feed.name,
    providerType: feed.providerType,
    gateways: plainGateways(feed.gateways),
  };
};
